import { useNotificationContext } from "context/notificationContext";
import Notifications from "shared/ui/Notifications";

const Notifier = () => {
  const { notificationList, removeNotification } = useNotificationContext();

  if (!notificationList.length) return null;

  return (
    <Notifications list={notificationList} removeItem={removeNotification} />
  );
};

export default Notifier;

import { useState, useLayoutEffect } from "react";
import { createPortal } from "react-dom";

const Portal = ({ children, wrapperId = "react-portal-wrapper" }) => {
  const [wrapperElement, setWrapperElement] = useState(null);

  const createWrapperAndAppendToBody = (id) => {
    const wrapperEl = document.createElement("div");

    wrapperEl.setAttribute("id", id);
    document.body.appendChild(wrapperEl);

    return wrapperEl;
  };

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let systemCreated = false;

    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToBody(wrapperId);
    }
    setWrapperElement(element);

    return () => {
      if (systemCreated && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
};

export default Portal;
